import { INgSelect } from '~shared/interface/INgSelect';

export class FormGroupCompare {
	public static isDifferentForm(obj1: Record<string, unknown>, obj2: Record<string, unknown>): boolean {
		const response1 = this._compareForm1toForm2(obj1, obj2);
		const response2 = this._compareForm1toForm2(obj2, obj1);
		if (response1 || response2) return true;
		else return false;
	}

	private static _compareForm1toForm2(o1: Record<string, unknown>, o2: Record<string, unknown>): boolean {
		return Object.keys(o1).some((res) => {
			const isNullOrUndefined = (val: any) => val === null || val === undefined;

			if (Array.isArray(o1[res]) || Array.isArray(o2[res])) {
				if (isNullOrUndefined(o1[res]) && !isNullOrUndefined(o2[res])) {
					return true;
				} else if (!isNullOrUndefined(o1[res]) && isNullOrUndefined(o2[res])) {
					return true;
				} else if (!isNullOrUndefined(o1[res]) && !isNullOrUndefined(o2[res])) {
					const arr1 = o1[res] as Array<INgSelect>;
					const arr2 = o2[res] as Array<INgSelect>;

					if (arr1.length !== arr2.length) {
						return true;
					} else {
						const checkArray = (a1: Array<INgSelect>, a2: Array<INgSelect>) => {
							return a1.some((elem) => a2.some((r) => elem.value == r.value));
						};

						if (arr2.length > 0 && !checkArray(arr1, arr2)) {
							return true;
						}

						if (arr1.length > 0 && !checkArray(arr2, arr1)) {
							return true;
						}
					}
				}
			} else if (o1[res] instanceof Object || o2[res] instanceof Object) {
				if (isNullOrUndefined(o1[res]) && !isNullOrUndefined(o2[res])) {
					return true;
				} else if (!isNullOrUndefined(o1[res]) && isNullOrUndefined(o2[res])) {
					return true;
				} else if (!isNullOrUndefined(o1[res]) && !isNullOrUndefined(o2[res])) {
					if ((o1[res] as Record<string, unknown>).value !== (o2[res] as Record<string, unknown>).value) {
						return true;
					}
				}
			} else if (isNullOrUndefined(o1[res]) && !isNullOrUndefined(o2[res])) {
				return true;
			} else if (!isNullOrUndefined(o1[res]) && o1[res] !== '' && isNullOrUndefined(o2[res])) {
				return true;
			} else if (o1[res] != o2[res]) {
				if (o1[res] === '' && isNullOrUndefined(o2[res])) {
					return false;
				}
				return true;
			}
			return false;
		});
	}
}
